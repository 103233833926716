import api from './api';

const ROOT = { root: true };

export const defaultState = {
  page: 1,
  numPages: 1,
  filters: [],
  data: null,
  order: null,
  ascending: false
};

export function defaultSet( state, payload ) {
  for ( var key in payload ) {
    state[key] = payload[key];
  }
}

export function request( store, path, payload, cache, method = 'post' ) {

  let {
    silence,
    save = cache,
    options,
    formData,
    ...data
  } = payload || {};

  if ( method === 'delete' ) {
    data = { ...options, data };
    options = undefined;
  }

  ! silence && store.commit( 'app/set', { loading: true }, ROOT );

  return api[method]( path, formData || data, options ).then( data => {
    save && store.commit( 'set', data );
    return data;
  }).finally(() => {
    ! silence && store.commit( 'app/set', { loading: false }, ROOT );
  });
}

export function paginate( store, path, payload, cache, method ) {

  payload = payload || {};

  if ( typeof payload.page !== 'number' || payload.page < 1 ) {
    payload.page = 1;
  }

  if ( ! Array.isArray( payload.filters )) {
    payload.filters = [];
  }

  cache && payload.save !== false && store.commit( 'set', payload );
  return request( store, path, payload, cache, method );
}

export function importData( store, path, payload ) {

  payload = payload || {};
  payload.options = payload.options || {};
  payload.options.headers = payload.options.headers || {};
  payload.options.headers['Content-Type'] = 'multipart/form-data';

  return request( store, path, payload );
}
