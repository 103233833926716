function load( view ) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

export default [
  {
    path: '/',
    redirect: '/profile'
  },{
    path: '/profile',
    component: load('Users/User')
  },{
    path: '/users',
    component: load('Users/index')
  },{
    path: '/users/:user',
    component: load('Users/User')
  },{
    path: '/app/users',
    component: load('Users/index')
  },{
    path: '/app/users/:user',
    component: load('Users/User')
  },{
    path: '/area',
    component: load('Areas/index')
  },{
    path: '/category',
    component: load('Categories/index')
  },{
    path: '/category-filter',
    component: load('CategoryFilters/index')
  },{
    path: '/category-filter-item',
    component: load('CategoryFilterItems/index')
  },{
    path: '/customer',
    component: load('Customers/index')
  },{
    path: '/offer',
    component: load('Offers/index')
  },{
    path: '/booking',
    component: load('OfferMatch/index')
  },{
    path: '/tag',
    component: load('Tags/index')
  },{
    path: '/login',
    component: load('Login')
  },{
    path: '/recovery-pass',
    component: load('RecoveryPass')
  },{
    path: '/reset-pass',
    component: load('ResetPass')
  },{
    path: '*',
    redirect: '/'
  }
];
