import { defaultActions, defaultState, defaultSet, request } from '@/utils/api';

export default {
  state: {
    ...defaultState
  },
  mutations: {
    set: defaultSet
  },
  actions: {
    ...defaultActions( '/offer', true ),
    getTimetable( ctx, { id }) {
      return request( ctx, `/offerTimetable/get?id=${id}`, {}, false, 'get' );
    },
    setTimetable( ctx, payload ) {
      return request( ctx, '/offerTimetable/set', payload );
    }
  }
};
