import { camelize } from 'vuetify/lib/util/helpers';

const icons = {};
const req = require.context( './', false, /\.vue$/);

req.keys().forEach( filePath => {
  const name = camelize( filePath.split('/').pop().replace( /\.vue$/, '' ));
  if ( name.charAt(0) === '_' ) return;
  if ( ! icons[name] ) icons[name] = {
    component: req( filePath ).default 
  };
});

export default icons;
