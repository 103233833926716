import { request } from '@/utils/api';

const computeOptions = data => {
  if ( data instanceof FormData ) return { formData: data };
  else {
    data = { formData: new FormData(), ...data };
    if ( data.file ) data.formData.append( 'file', data.file );
    if ( data.files ) data.files.forEach( file => data.formData.append( 'file', file ));
    delete data.file;
    delete data.files;
    return data;
  }
};

export default {
  actions: {
    file( ctx, payload ) {
      return request( ctx, '/upload/file', computeOptions( payload ), false );
    },
    images( ctx, payload ) {
      return request( ctx, '/upload/images', computeOptions( payload ), false );
    }
  }
}
