<template>
  <v-dialog
    v-model="open"
    v-bind="dialogProps"
    v-on="$listeners"
  >
    <v-card :tile="fullscreen">

      <v-card-title
        v-if="hasTitle"
        v-bind="headerProps"
      >
        <slot name="title">
          {{ title }}
        </slot>

        <btn
          v-if="!fullscreen && !hasActions && !persistent"
          class="ml-auto"
          small icon
          @click="cancel"
        >
          <v-icon small>mdi-close</v-icon>
        </btn>

      </v-card-title>

      <v-divider/>

      <v-card-text
        class="pa-3"
        :style="{ height: internalHeight }"
      >

        <slot/>

      </v-card-text>

      <v-divider/>

      <v-card-actions
        v-if="hasActions"
        :style="footerStyle"
      >
        <slot name="actions">
          <v-spacer/>

          <btn class="text-none" @click="cancel" text>
            {{ cancelText || $t('btn.cancel') }}
          </btn>

          <btn
            class="text-none"
            :color="color || 'primary'"
            @click="accept"
            dark
          >
            {{ acceptText || $t('btn.accept') }}
          </btn>

        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VDialog from 'vuetify/lib/components/VDialog';
import colorable from 'vuetify/lib/mixins/colorable';
import { convertToUnit } from 'vuetify/lib/util/helpers';
import { COLOR_BASE } from '@/utils/constants';

export default {
  components: { VDialog },
  mixins: [ colorable ],
  props: {
    ...VDialog.options.props,
    height: [ Number, String ],
    headerHeight: [ Number, String ],
    footerHeight: [ Number, String ],
    title: String,
    color: {
      type: String,
      default: COLOR_BASE
    },
    actions: {
      type: Boolean,
      default: true
    },
    cancelText: String,
    acceptText: String
  },
  data() {
    return {
      open: !!this.value
    }
  },
  computed: {
    dialogProps() {
      return Object.keys( VDialog.options.props ).reduce(( obj, prop ) => {
        obj[prop] = this[prop];
        return obj;
      },{});
    },
    internalHeight() {
      if ( this.fullscreen ) return;

      let less = 0;
      let height = Number( String( this.height ).replace(/px$/,''));
      if ( this.hasTitle ) less += 59;
      if ( this.hasActions ) less += 53;

      if ( isNaN( height )) {
        if ( ! less ) return this.height;
        return `calc( ${this.height} - ${less}px )`;
      }

      return convertToUnit( Math.max( height - less, 0 ));
    },
    headerProps() {
      return this.setBackgroundColor( this.color, {
        style: this.headerStyle,
        class: {
          'white--text': this.color,
          'pa-3': 1
        }
      });
    },
    headerStyle() {
      return {
        minHeight: convertToUnit( this.headerHeight )
      }
    },
    footerStyle() {
      return {
        minHeight: convertToUnit( this.footerHeight )
      }
    },
    hasTitle() {
      return this.title || !!this.$slots.title;
    },
    hasActions() {
      return this.actions || !!this.$slots.action;
    }
  },
  watch: {
    value( value ) {
      this.open = !!value;
    },
    open( value ) {
      this.$emit( 'input', value );
    }
  },
  methods: {
    cancel(e) {
      this.open = false;
      this.$emit( 'click:cancel', e );
    },
    accept(e) {
      if ( ! this.persistent ) this.open = false;
      this.$emit( 'click:accept', e );
    }
  }
}
</script>
