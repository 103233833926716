import api from '@/utils/api';
import ClientOAuth2 from 'client-oauth2';
import { OAUTH_OPTIONS, USER_ROLES, TIME_TO_LOGOUT } from '@/utils/constants';

let securityInterval;

export default {
  state: {
    from: '/',
    token: null,
    userId: 0,
    isAdmin: false,
    isSuperAdmin: false,
    user: {}
  },
  mutations: {
    setFrom( state, from ) {
      state.from = from || '/';
    },
    setToken( state, data ) {
      data = api.setToken( data );
      if ( ! data ) {
        state.token = null;
        state.userId = 0;
        state.user = {};
        state.isAdmin = false;
        state.isSuperAdmin = false;
      } else {
        state.token = data.token;
      }
    },
    setUser( state, user ) {
      user = user || {};
      state.user = user;
      state.userId = user.id || 0;
      state.isAdmin = user.role === USER_ROLES.ADMIN;
      state.isSuperAdmin = user.role === USER_ROLES.SUPERADMIN;
    }
  },
  actions: {
    login({ commit, dispatch },{ username, password }) {
      const oauth = new ClientOAuth2( OAUTH_OPTIONS );
      return oauth.owner.getToken( username, password ).then( res => {
        dispatch('startSecurity');
        commit( 'setToken', res );
        return res;
      }).catch( err => {
        commit( 'setToken' );
        throw err;
      });
    },
    logout({ commit }) {
      clearInterval( securityInterval );
      commit('setToken');
      return true;
    },
    refreshToken({ state, commit }) {
      return new Promise( resolve => {

        const data = api.getToken();

        if ( ! data.token || data.token === 'undefined' ) {
          return resolve();
        }

        if ( parseInt( data.expires ) > Date.now()) {
          commit( 'setToken', data );
          return resolve( !state.userId && data );
        }

        const oauth = new ClientOAuth2( OAUTH_OPTIONS );
        const token = oauth.createToken( data.token, data.refreshToken, data.type, { data: {} });

        token.expiresIn( new Date( data.expires ));
        token.refresh().then( res => {

          commit( 'setToken', res );
          resolve( res );

        }).catch( err => {

          console.error( err );
          commit( 'setToken' );
          resolve();

        });
      });
    },
    fetchUser({ commit }) {
      return api.get('/user/get').then( user => {

        if ( user.role === USER_ROLES.USER )
          throw new Error('Permission denied');

        commit( 'setUser', user );
        return user;

      }).catch( err => {
        commit( 'setToken' );
        return err;
      });
    },
    startSecurity({ dispatch }) {
      clearInterval( securityInterval );
      securityInterval = setTimeout(() => {
        dispatch('logout');
        dispatch( 'app/caducityAlert', null, { root: true });
      }, TIME_TO_LOGOUT );
    },
    recoveryPass( ctx, payload ) {
      return api.post( '/user/password/reset', payload );
    },
    resetPass( ctx, payload ) {
      return api.post( '/user/password/set', payload );
    }
  }
};
