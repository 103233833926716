<template>
  <component
    :is="computedLayout"
    v-bind="props"
  />
</template>

<script>
import Layouts from '@/layouts';
import { mapState } from 'vuex';

export default {
  components: Layouts,
  head: {
    title: 'CMS',
    titleTemplate: '%s | Nomade'
  },
  computed: {
    ...mapState( 'auth', [ 'userId', 'from' ]),
    ...mapState( 'app', [ 'layout', 'props' ]),
    computedLayout() {
      if ( Layouts[ this.layout ]) return this.layout;
      return 'default';
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  watch: {
    userId( userId ) {
      if ( userId ) {
        if ( this.$route.fullPath !== this.from )
          this.$router.push( this.$lp( this.from ));
      } else {
        this.$router.push( this.$lp('/login'));
      }
    },
    isMobile( value ) {
      this.$store.commit( 'app/set', { isMobile: value });
    }
  },
  beforeMount() {
    this.$store.commit( 'app/set', { isMobile: this.isMobile });
  }
}
</script>
