import { DEFAULT_LANGUAGE } from '@/utils/constants';
import i18n from '@/plugins/i18n';
let alertCount = 0;

export default {
  state: {
    layout: 'default',
    lang: DEFAULT_LANGUAGE,
    props: {},
    alerts: [],
    loading: false,
    progress: 0,
    drawer: true,
    drawerMin: null,
    isMobile: false
  },
  mutations: {
    set( state, payload = {} ) {
      for ( var key in payload ) {
        state[key] = payload[key];
      }
    },
    addAlert( state, alert ) {
      state.alerts.push( alert );
    },
    removeAlert( state, alert ) {
      var index = state.alerts.indexOf( alert );
      if ( index !== -1 ) {
        state.alerts.splice( index, 1 );
      }
    }
  },
  getters: {
    statusMap( state ) {
      return {
        '-1': i18n.t('inputs.status.false', state.lang ),
        '1': i18n.t('inputs.status.true', state.lang )
      };
    },
    statusItems( state, getters ) {
      return Object.keys( getters.statusMap ).map( val => ({
        value: Number( val ),
        text: getters.statusMap[val]
      }));
    },
    imageDimension( state ) {
      return state.isMobile ? 50 : 75;
    },
  },
  actions: {
    alert({ commit }, alert ) {

      alert = {
        id: alertCount++,
        type: 'info',
        message: '',
        permanent: false,
        timeout: 5000,
        ...alert
      };

      alert.remove = () => {
        clearInterval( alert.interval );
        commit( 'removeAlert', alert );
      };

      commit( 'addAlert', alert );
      if ( ! alert.permanent ) {
        alert.interval = setTimeout( alert.remove, alert.timeout );
      }

      return alert;
    },
    success({ dispatch }, alert ) {
      return dispatch( 'alert', { ...alert, type: 'success' });
    },
    warning({ dispatch }, alert ) {
      return dispatch( 'alert', { ...alert, type: 'warning' });
    },
    error({ dispatch }, alert ) {
      return dispatch( 'alert', { ...alert, type: 'error' });
    },
    caducityAlert({ dispatch, state }) {
      dispatch( 'error', {
        message: i18n.t( 'alerts.sesionFinished', state.lang )
      });
    }
  }
}
