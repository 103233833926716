<template>
  <v-sheet
    class="logotype"
    v-bind="computedColor"
    :color="bgcolor"
    :height="height"
  >
    <logo-min-svg v-if="min"/>
    <logo-svg v-else/>
    <span v-if="showVersion" class="logotype--version">
      v {{ version }}
    </span>
  </v-sheet>
</template>

<script>
import LogoSvg from './LogoSvg';
import LogoMinSvg from './LogoMinSvg';
import colorable from 'vuetify/lib/mixins/colorable';
import { VERSION } from '@/utils/constants';

export default {
  components: { LogoSvg, LogoMinSvg },
  mixins: [ colorable ],
  props: {
    height: {
      type: [ Number, String ],
      default: 92
    },
    bgcolor: {
      type: String,
      default: 'transparent'
    },
    color: {
      type: String,
      default: 'black'
    },
    min: Boolean,
    showVersion: Boolean
  },
  data: () => ({
    version: VERSION
  }),
  computed: {
    computedColor() {
      return this.setTextColor( this.color );
    }
  }
}
</script>

<style>
.logotype {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logotype svg {
  height: 100%;
  width: auto;
}
.logotype .logotype--version {
  position: absolute;
  font-size: 12px;
  left: 63%;
  bottom: 10%;
}
</style>
