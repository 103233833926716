import path from 'path';
import { camelize } from 'vuetify/lib/util/helpers';

const layouts = {};
const req = require.context( './', false, /\.vue$/, 'lazy' );

req.keys().forEach( filePath => {
  const name = camelize( path.basename( filePath.replace( /\.vue$/, '' )));
  layouts[name] = () => req( filePath );
});

export default layouts;
