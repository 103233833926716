import Vue from 'vue';
import Router from 'vue-router';
import { sortRoutes } from '@/utils';
import routes from './routes';

Vue.use( Router );

function computeView(c) {

  const comp = c.default || c;
  const beforeMount = comp.beforeMount;
  const $route = comp.watch && comp.watch.$route;

  const change = fn => function(){
    const data = comp._fetchedData;
    data && Object.keys( data ).forEach( prop => this[prop] = data[prop]);
    return fn && fn.apply( this, arguments );
  };

  comp.beforeMount = change(beforeMount);
  comp.watch = { ...comp.watch, $route: change($route) };

  return c;
}

function computeRoutes( routes ) {
  if ( ! routes ) return;
  routes.forEach( r => {
    if ( ! r.component ) return;
    if ( typeof r.component === 'function' ) {
      const fn = r.component;
      r.component = () => Promise.resolve(fn()).then( computeView );
    } else {
      r.component = computeView( r.component );
    }
    computeRoutes( r.children );
  });
  return routes;
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: computeRoutes( sortRoutes( routes )),
  scrollBehavior( to, from, savedPosition ) {
    if ( savedPosition ) return savedPosition;
    return { x: 0, y: 0 }
  }
});

// MIDDLEWARES

const set = payload => router.ctx.store.commit( 'app/set', payload );
const progress = ( progress, loading = true ) => set({ loading, progress });

// - Start
router.beforeEach(( to, from, next ) => {
  router.ctx.redirect = null;
  router.ctx.route = to;
  router.ctx.routeFrom = from;
  progress(15);
  next();
});

// - Auth
router.beforeResolve(( to, from, next ) => {
  router.ctx.store
    .dispatch('auth/refreshToken')
    .then( res => res && router.ctx.store.dispatch('auth/fetchUser'))
    .finally(() => {

      let mode;
      to.matched.forEach( r => {
        Object.values( r.components ).forEach( component => {
          if ( component.auth != null )
            mode = component.auth;
        });
      });

      const { userId } = router.ctx.store.state.auth;

      Promise
        .resolve( typeof mode === 'function' ? mode( router.ctx ) : mode )
        .then( mode => {
          if ( ! userId ) {
            if ( mode === true ) {
              router.ctx.store.commit('auth/setFrom', to.fullPath );
              next('/login');
            } else if ( mode && mode !== 'guess' ) {
              next( mode ); // Custom redirect if user not login
            } else {
              next();
            }
          } else {
            router.ctx.store.dispatch('auth/startSecurity');
            if ( mode === 'guess' ) next( from );
            else next();
          }
        })
        .catch( err => {
          next( new Error( err ));
        });
    });
});

// - FetchData
router.beforeResolve(( to, from, next ) => {

  progress(50);
  const fetchData = [];
  let loaded = 0;

  let redirect;
  router.ctx.redirect = location => {
    redirect = redirect == null ? location : redirect;
  };

  to.matched.forEach( r => {
    return Object.keys( r.components ).map( k => {

      const comp = r.components[k];
      comp._fetchedData = null;

      if ( typeof comp.fetchData === 'function' ) {
        fetchData.push(
          Promise.resolve( comp.fetchData( router.ctx )).then( res => {
            progress( 50 + (++loaded/fetchData.length) * 40 );
            comp._fetchedData = res;
            return res;
          })
        );
      }
    });
  });

  return Promise
    .all( fetchData )
    .then(() => next( redirect ))
    .catch( err => {
      progress( 100, false );
      next( new Error( err ));
    });
});

// - Layout
router.beforeResolve(( to, from, next ) => {

  progress(90);

  const props = [];
  const layouts = ['default'];

  to.matched.forEach( r => {
    return Object.keys( r.components ).map( k => {

      let { layout, layoutProps, _fetchedData } = r.components[k];

      if ( layout ) {
        layouts.push( typeof layout === 'function'
          ? layout( router.ctx, _fetchedData || {} )
          : layout
        );
      }

      if ( layoutProps ) {
        props.push(
          typeof layoutProps === 'function'
            ? layoutProps( router.ctx, _fetchedData || {} )
            : layoutProps
        );
      }
    });
  });

  return Promise.all( layouts ).then( res => {
    set({ layout: res.filter( Boolean ).pop() });
    return Promise.all( props );
  }).then( res => {
    set({ props: res.reduce(( obj, props ) => {
      Object.assign( obj, props || {} );
      return obj;
    },{}) });
  })
  .then(() => {
    progress( 100, false );
    next();
  })
  .catch( err => {
    progress( 100, false );
    next( new Error( err ));
  });
});

export default router;
