import api, { defaultActions, defaultState, defaultSet } from '@/utils/api';

export default {
  state: {
    ...defaultState
  },
  mutations: {
    set: defaultSet
  },
  actions: {
    ...defaultActions( '/user', true ),
    generatePassword( ctx, payload ) {
      return api.post( '/user/password/generate', payload );
    },
    addSocial( ctx, payload ) {
      return api.post( '/social/set', payload );
    }
  }
};
