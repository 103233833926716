import Vue from 'vue';
import Vuex from 'vuex';

const store = {};
const specialNames = [ 'state', 'mutations', 'actions', 'getters', 'modules' ];

const req = require.context( './store', true, /\.js$/ );
req.keys().forEach( filePath => {

  if ( filePath.charAt(0) === '_' ) return;

  const storePath = filePath.replace( /\.\/|\.js$/g, '' );
  const module = req( filePath );
  let parent = store;

  storePath.split('/').forEach(( name, i, arr ) => {

    let isLast = i === arr.length - 1;

    if ( ! ( isLast && name === 'index' )) {
      parent.modules = parent.modules || {};
      parent = parent.modules[name] = parent.modules[name] || {};
      parent.namespaced = true;
    }

    if ( ! isLast ) return;

    if ( name === 'index' ) {

      if ( module.default )
        Object.assign( parent, module.default );

      Object.keys( module ).forEach( key => {
        if ( key === 'default' ) return;
        parent[key] = { ...parent[key], ...module[key] };
      });

    } else if ( specialNames.includes( name )) {

      if ( module.default )
        parent[name] = { ...parent[name], ...module.default };

      Object.keys( module ).forEach( key => {
        if ( key === 'default' ) return;
        parent[name] = parent[name] || {};
        parent[name][key] = module[key];
      });

    } else {

      if ( module.default )
        Object.assign( parent, module.default );

      Object.keys( module ).forEach( key => {
        if ( key === 'default' ) return;
        parent[key] = { ...parent[key], ...module[key] };
      });
    }
  });
});

Vue.use( Vuex );

export default new Vuex.Store( store );
