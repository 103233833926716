import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Router from 'vue-router';
import path from 'path';
import api from '@/utils/api';
import moment from 'moment';
import router from '@/router';
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE, SUPPORTED_LANGUAGES } from '@/utils/constants';
import { sortRoutes } from '@/utils';

// TRANSLATOR PLUGIN

Vue.use( VueI18n );

const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE
});

const Trans = {
  get locale() {
    return i18n.locale;
  },
  set locale( lang ) {
    i18n.locale = lang;
  },
  isLangSupported( lang ) {
    return SUPPORTED_LANGUAGES.includes( lang );
  },
  getUserSupportedLang() {

    const userLang = Trans.getUserLang();

    // Check if user preferred browser lang is supported
    if ( Trans.isLangSupported( userLang.lang )) {
      return userLang.lang;
    }

    // Check if user preferred lang without the ISO is supported
    if ( Trans.isLangSupported( userLang.langNoISO )) {
      return userLang.langNoISO;
    }

    return DEFAULT_LANGUAGE;
  },
  getUserLang() {
    const lang = window.navigator.language || window.navigator.userLanguage || DEFAULT_LANGUAGE;
    return {
      lang: lang,
      langNoISO: lang.split('-')[0]
    };
  },
  setLanguageInServices( lang ) {
    Trans.locale = lang;
    api.defaults.headers.common['Accept-Language'] = lang;
    moment.locale( lang );
    document.querySelector('html').setAttribute( 'lang', lang );
    router.ctx.store.commit( 'app/set', { lang });
    return lang;
  },
  changeLanguage( lang, force ) {

    if ( ! Trans.isLangSupported( lang ))
      return Promise.reject( new Error('Language not supported'));

    if ( ! force && i18n.locale === lang )
      return Promise.resolve( lang );

    return Trans.loadLanguageFile( lang ).then( msgs => {
      i18n.setLocaleMessage( lang, msgs.default || msgs );
      return Trans.setLanguageInServices( lang );
    });
  },
  loadLanguageFile( lang ) {
    return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${ lang }.js`);
  },
  localeRoute( route, lang = Trans.locale ) {

    if ( ! route ) return route;

    let splitted;
    if ( ! Trans.isLangSupported( lang ))
      lang = Trans.getUserSupportedLang();

    // Redirect by path

    let routePath = typeof route === 'string' ? route : route.fullPath;
    if ( routePath ) {
      splitted = routePath.replace(/^\//,'').split('/');
      if ( ! splitted[0] || Trans.isLangSupported( splitted[0] )) splitted.shift();
      if ( lang !== DEFAULT_LANGUAGE ) splitted.unshift( lang );
      return '/' + splitted.join('/');
    }

    // Redirect by name

    if ( route.name ) {
      splitted = route.name.split('-');
      if ( Trans.isLangSupported( splitted[0] )) splitted.shift();
      if ( lang !== DEFAULT_LANGUAGE ) splitted.unshift( lang );
      return { name: splitted.join('-') };
    }
  },
  makeRoute( route, lang, fullPath = '' ) {

    route = { ...route };

    if ( route.name )
      route.name = `${lang}-${route.name}`;

    route.path = route.path.replace(/^\//,'');

    const { redirect } = route;
    if ( route.path && route.path !== '*' && lang === DEFAULT_LANGUAGE ) {

      fullPath = path.join( fullPath, route.path );
      route.redirect = '/' + fullPath;

    } else if ( typeof redirect === 'function' ) {

      route.redirect = function() {
        return Trans.localeRoute( redirect.apply( this, arguments ), lang );
      }

    } else if ( redirect ) {

      route.redirect = Trans.localeRoute( redirect, lang );
    }

    if ( route.children )
      route.children = route.children.map( r => Trans.makeRoute( r, lang, fullPath ));

    return route;
  },
  makeRoutes( routes ) {

    const arr = [];

    // Create lang versions

    SUPPORTED_LANGUAGES.forEach( lang => {

      const route = {
        path: '/' + lang,
        component: { render(h){ return h('router-view') }},
        beforeEnter: Trans.routeMiddleware( lang ),
        children: routes.map( r => Trans.makeRoute( r, lang ))
      };

      if ( DEFAULT_LANGUAGE === lang )
        route.redirect = '/';

      arr.push( route );
    });

    // Add lang middleware

    const middleware = Trans.routeMiddleware( DEFAULT_LANGUAGE );
    routes.forEach( route => {
      const { beforeEnter } = route;
      route.beforeEnter = beforeEnter
        ? ( to, from, next ) => middleware( to, from, () => beforeEnter( to, from, next ))
        : middleware;
    });

    return sortRoutes( routes.concat( arr ));
  },
  routeMiddleware( lang ) {
    return ( to, from, next ) => {
      return Trans.changeLanguage( lang ).then(() => next());
    };
  },
};

// Make lang routes

if ( SUPPORTED_LANGUAGES.length > 1 ) {

  const newRouter = new Router({
    ...router.options,
    routes: Trans.makeRoutes( router.options.routes )
  });

  router.matcher = newRouter.matcher;
}

// Add i18n to context
router.ctx = { ...router.ctx, i18n, lp: Trans.localeRoute };

// Add instance function localeRoute
Vue.prototype.$lp = Trans.localeRoute;

// Set defualt user lang

const userLang = Trans.getUserSupportedLang();
Trans.changeLanguage( Trans.getUserSupportedLang(), userLang === DEFAULT_LANGUAGE );

export default i18n;
