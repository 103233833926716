import Vue from 'vue';
import App from './App';
import store from './create-store';
import router from './router';
import plugins from './plugins';
import components from './components/global';

// Styles
import './styles/main.css';

// Global Components
Vue.mixin({ components });

// Create context
const context = { ...router.ctx, store, router };
store.ctx = router.ctx = context;

Vue.config.productionTip = false;

new Vue({
  ...plugins,
  router,
  store,
  render: h => h( App )
}).$mount('#app');
