import { request } from '../utils';

export default ( baseUrl, cache ) => ( ctx, payload = {}) => {
  return request( ctx, `${baseUrl}/export`, payload, cache ).then( content => {

    console.log(JSON.stringify(content));
    //const blob = new Blob([ content ],{ type: 'text/csv' });
    const link = document.createElement('a');

    //link.href = window.URL.createObjectURL( blob );
    link.href = content;
    link.target = '_blank';
    link.download = `${baseUrl.split('/').pop()}-export.csv`;
    link.onclick = function() {
      // revokeObjectURL needs a delay to work properly
      setTimeout(() => window.URL.revokeObjectURL(link.href), 1500 );
    };

    link.click();
    link.remove();

    return content;
  });
};
