import { defaultActions, defaultState, defaultSet } from '@/utils/api';

export default {
  state: {
    ...defaultState
  },
  mutations: {
    set: defaultSet
  },
  actions: {
    ...defaultActions( '/tag', true )
  }
};
