const path = require('path');
const m = {};

//const BASE_URL = "http://localhost:8080";
//const BASE_URL = "https://nomade.inmovens.com";
const BASE_URL = "https://admin.nomaderevolution.com";

m.IS_PROD = process.env.NODE_ENV == 'production';
m.DEBUG = !m.IS_PROD;
m.PORT = process.env.PORT || 4545;
m.VERSION = '1.0.0';

m.BASE_URL = m.IS_PROD
  ? ( process.env.BASE_URL || BASE_URL )
  : `http://localhost:${m.PORT}`;

m.IMAGE_BASE_URL = BASE_URL + '/resources/images';

m.FILES_BASE_URL = BASE_URL + '/resources/files';

// API constants

m.API_BASE = m.IS_PROD ? '/api' : '/pre';
m.PROXY = {
  [m.API_BASE]: {
    target: BASE_URL,
    pathRewrite: { '^/pre': 'api' },
    changeOrigin: true,
    secure: false
  }
};

m.API_URL = m.IS_PROD
  ? path.join( BASE_URL, 'api' )
  : m.API_BASE;

m.WEBSOCKET_URL = path.join( m.API_BASE, 'websocket' );
m.OAUTH_OPTIONS = {
  clientId: "user",
  clientSecret: "123456aB!",
  accessTokenUri: path.join( m.API_BASE, 'oauth', 'token' ),
  authorizationUri: path.join( m.API_BASE, 'oauth', 'token' ),
  scopes: ["read"],
};

// Language

m.DEFAULT_LANGUAGE = 'es';
m.FALLBACK_LANGUAGE = 'es';
m.SUPPORTED_LANGUAGES = ['es'];

// Others

m.TIME_TO_LOGOUT = 1000 * 60 * 60 * 24; // 24 horas

m.ICONS = {
  profile: '$account',
  users: '$users',
  appUsers: '$users',
  area: '$area',
  category: '$category',
  categoryFilter: '$filter',
  categoryFilterItem: '$subcategory',
  customer: '$customer',
  offer: '$offer',
  offerMatch: '$booking',
  tag: '$tag'
};

m.USER_ROLES = {
  ADMIN: 99,
  MANAGER: 1,
  EDITOR: 2,
  USER: 0
};

m.USER_STATUS = {
  PENDING: -2,
  BANNED: -1,
  DISABLED: 0,
  ENABLED: 1
};

m.USER_STATUS_COLOR = {
  [m.USER_STATUS.PENDING]: 'info',
  [m.USER_STATUS.BANNED]: 'error',
  [m.USER_STATUS.DISABLED]: '',
  [m.USER_STATUS.ENABLED]: 'success'
};

m.OFFER_STATUS = {
  DISABLED: 0,
  ENABLED: 1,
  BLOCKED: 2
};

m.CATEGORY_STATUS = {
  DISABLED: -1,
  ENABLED: 1,
  BLOCKED: 2
};

m.MAX_CATEGORIES = 7;

m.BOOKING_STATUS = {
  CANCELLED: -1,
  UNPLUBLISHED: 0,
  REQUIRED: 1,
  ACCEPTED: 2,
  REFUSED: 3
};

m.BOOKING_STATUS_COLOR = {
  [m.BOOKING_STATUS.CANCELLED]: '',
  [m.BOOKING_STATUS.UNPLUBLISHED]: 'black',
  [m.BOOKING_STATUS.REQUIRED]: 'info',
  [m.BOOKING_STATUS.ACCEPTED]: 'success',
  [m.BOOKING_STATUS.REFUSED]: 'error'
};

module.exports = m;
