import path from 'path';

const actions = {};
const req = require.context( './', false, /\.js$/ );

req.keys().forEach( filePath => {

  const name = path.basename( filePath.replace( /\.js$/, '' ));
  if ( name === 'index' ) return;

  actions[name] = req( filePath ).default;
});

export default actions;
