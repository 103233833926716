import Axios from 'axios';
import { API_BASE, DEBUG } from '@/utils/constants';

const api = Axios.create({
  baseURL: API_BASE,
  headers: { 'Content-type': 'application/json' }
});

export const RESPONSE_PROPS = {
  accessToken: 'token',
  refreshToken: 'refreshToken',
  tokenType: 'type',
  tokenExpiration: 'expires'
};

api.getToken = () => ({
  token: localStorage.getItem('accessToken'),
  type: localStorage.getItem('tokenType'),
  refreshToken: localStorage.getItem('refreshToken'),
  expires: localStorage.getItem('tokenExpiration'),
});

api.setToken = data => {
  if ( ! data ) {

    api.defaults.headers.common.authorization = null;
    Object.keys( RESPONSE_PROPS ).forEach( key => {
      localStorage.removeItem( key );
    });

  } else {

    data = {
      token: data.access_token || data.accessToken || data.token,
      type: data.token_type || data.tokenType || data.type,
      refreshToken: data.refresh_token || data.refreshToken,
      expires: data.expires_in
        ? new Date( Date.now() + data.expires_in ).getTime()
        : Number( data.expires )
    };

    if ( data.token ) {
      api.defaults.headers.common.authorization = 'Bearer ' + data.token;
    }

    Object.keys( RESPONSE_PROPS ).forEach( key => {
      var k = RESPONSE_PROPS[key];
      data[k] && localStorage.setItem( key, data[k] );
    });
  }

  return data;
};

// Interceptors

api.interceptors.response.use( response => {

  response = {
    status: response.status,
    url: response.request.responseURL,
    payload: response.config.data,
    headers: response.config.headers,
    data: response.data
  };

  if ( response.data.error ) {
    DEBUG && console.error("[API ERROR]", response );
    throw response.data;
  }

  DEBUG && console.log( "[API SUCCESS]:", response);
  return response.data.object || response.data;

}, err => {
  console.error( "[API ERROR]:\n", err );
  return Promise.reject( err );
});

export default api;
