import api from './api';
import actions from './defaults';
export { request, paginate, importData, defaultState, defaultSet } from './utils';

export default api;

export function defaultActions( baseUrl, cache ) {

  if ( ! baseUrl ) return;

  return Object.keys( actions ).reduce(( obj, name ) => {
    obj[name] = actions[name]( baseUrl, cache );
    return obj;
  },{});
}
