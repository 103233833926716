import moment from 'moment';
import { filterObjectOnKeys } from 'vuetify/lib/util/helpers';

export function cleanObject( obj, keys ) {
  return filterObjectOnKeys( obj, keys || Object.keys( obj ));
}

const DYNAMIC_ROUTE_REGEX = /^\/([:*])/;
const _sortRoute = ( a, b ) => {

  // Order: /static, /index, /:dynamic

  if ( ! a.path || ! a.path.length ) return -1;
  if ( ! b.path || ! b.path.length ) return 1;

  if ( a.path === '/' ) return DYNAMIC_ROUTE_REGEX.test( b.path ) ? -1 : 1;
  if ( b.path === '/' ) return DYNAMIC_ROUTE_REGEX.test( a.path ) ? 1 : -1;

  let i;
  let res = 0;
  let y = 0;
  let z = 0;
  const _a = a.path.split('/');
  const _b = b.path.split('/');

  for ( i = 0; i < _a.length; i++ ) {

    if ( res !== 0 ) break;

    y = _a[i] === '*' ? 2 : _a[i].includes(':') ? 1 : 0;
    z = _b[i] === '*' ? 2 : _b[i].includes(':') ? 1 : 0;
    res = y - z;

    if ( i === _b.length - 1 && res === 0 ) {
      res = _a[i] === '*'
        ? -1
        : _a.length === _b.length
          ? a.path.localeCompare( b.path )
          : ( _a.length - _b.length );
    }
  }

  if ( res === 0 ) {
    res = _a[i - 1] === '*' && _b[i]
      ? 1
      : _a.length === _b.length
        ? a.path.localeCompare( b.path )
        : ( _a.length - _b.length );
  }

  return res;
};

export function sortRoutes( routes ) {
  routes.sort( _sortRoute );
  routes.forEach( route => {
    if ( route.children ) {
      sortRoutes( route.children );
    }
  });
  return routes;
}

export function componentProps( oldProps, props ) {
  oldProps = { ...oldProps };
  Object.keys( props ).forEach( prop => {
    if ( oldProps[prop] ) {

      let value = props[prop];
      if ( typeof oldProps[prop] === 'function' || Array.isArray( oldProps[prop] )) {
        oldProps[prop] = { type: oldProps[prop] };
      } else {
        oldProps[prop] = { ...oldProps[prop] };
      }

      if ( value && typeof value === 'object' ) {
        oldProps[prop].default = () => value;
      } else {
        oldProps[prop].default = value;
      }
    }
  });
  return oldProps;
}

export function capitalize( str ) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function strRepeat( str, num = 1 ) {
  var result = '';
  for ( var i = 0; i < num; i++ ) result += str;
  return result;
}

export function digits( num, digits, invert ) {
  num = String( num == null ? '' : num );
  digits = digits > 0 ? digits : 0;
  let ceros = strRepeat( '0', digits ).slice( num.length );
  return invert ? num + ceros : ceros + num;
}

export function round( value, decimals = 0 ) {
  let t = Math.pow( 10, decimals );
  return Math.round( value * t ) / t;
}

export function countChars( str, chars ) {
  return (( str || '' ).match( new RegExp( `[${chars}]`, 'g' )) || '' ).length;
}

export function getMoment( value, format ) {

  if ( value == null ) return null;
  var m;

  if ( typeof value === 'string' && format ) {
    m = moment( value, format );
    if ( m.format( format ) !== value ) return null;
  } else {
    m = moment( value );
  }

  if ( ! m.isValid()) return null;
  return m;
}

export function createFilters( filters ) {
  const arr = [];
  Object.keys( filters ).forEach( field => {

    var value = filters[field];
    if ( value == null || value === '' ) return;

    switch ( typeof value ) {
      case 'string':
        arr.push({ field, stringValue: value });
        break;
      case 'number':
        arr.push({ field, intValue: value });
        break;
      case 'boolean':
        arr.push({ field, booleanValue: value });
        break;
      case 'object':
        if ( Array.isArray( value )) {
          arr.push({ field, listValue: value });
        } else if ( value instanceof Date ) {
          arr.push({ field, stringValue: value.toISOString() });
        } else if ( value ) {
          arr.push({ field, rangeValue: value });
        }
        break;
    }
  });
  return arr;
}

// Format

export function displayDate( value, time ) {
  let format = 'DD/MM/YYYY';
  if ( time ) format += ' HH:mm';
  return moment( value ).format( format );
}

export function displayTime( value ) {
  let format = 'HH:mm';
  return moment( value ).format( format );
}

const from  = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
const to    = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
const map   = {};

for( var i = 0, j = from.length; i < j; i++ )
    map[ from.charAt( i ) ] = to.charAt( i );

export function normalize( str, lower ) {
  var ret = [];
  for( var i = 0, j = str.length; i < j; i++ ) {
    var c = str.charAt( i );
    if ( map.hasOwnProperty( c )) ret.push( map[ c ] );
    else ret.push( c );
  }
  if ( lower ) return ret.join( '' ).toLowerCase();
  return ret.join( '' );
}
