import path from 'path';
import { camelize } from 'vuetify/lib/util/helpers';

const plugins = {};
const req = require.context( './', false, /\.js$/);

req.keys().forEach( filePath => {

  if ( filePath === './index.js' ) return;

  let dir = camelize( filePath.replace( /^\.\/|\.js$/g, '' ));
  let name = path.basename( dir );
  if ( name === 'index' ) name = path.basename( path.dirname( dir ));

  if ( name.charAt(0) === '_' ) return;

  const module = req( filePath ).default;
  if ( module ) plugins[name] = module;
});

export default plugins;
