import path from 'path';
import { camelize } from 'vuetify/lib/util/helpers';

const components = {};
const req = require.context( './', true, /\.vue$/);

req.keys().forEach( filePath => {

  const componentPath = filePath.replace( /\.\/|\.vue$/g, '' );
  if ( componentPath.split('/').some( str => str.charAt(0) === '_' )) return;

  let name = path.basename( componentPath );
  if ( name === 'index' ) name = path.basename( path.dirname( componentPath ));

  components[camelize(name)] = req( filePath ).default;
});

export default components;
