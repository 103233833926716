<template>
  <v-list
    :class="classes"
    :color="backgroundColor"
    v-bind="listProps"
  >
    <component
      :is="groupTag"
      v-bind="groupProps"
      @change="$emit( 'input', $event )"
    >
      <template v-for="item in computedItems">

        <v-subheader
          v-if="item.header"
          :key="`${item.header}-${item.index}`"
          :inset="item.inset"
          v-text="item.header"
        />

        <v-divider
          v-else-if="item.divider"
          :key="`divider-${item.index}`"
          :inset="item.inset"
        />

        <v-list-item
          v-else
          :key="`${item.title}-${item.index}`"
          :to="item.item.to"
          :ripple="false"
          :title="item.title"
          :exact="item.item.exact"
          :inactive="inactive"
          class="px-3"
          @click="$emit( 'click:item', item.item )"
          @dblclick="$emit( 'dblclick:item', item.item )"
        >

          <v-list-item-avatar
            v-if="item.avatar"
            v-bind="item.avatar.props"
            :size="item.avatar.props.size || iconSize"
          >
            <slot name="avatar" v-bind="item">
              <v-icon
                v-if="item.avatar.icon"
                v-bind="item.avatar.icon.props"
              >
                {{ item.avatar.icon.name }}
              </v-icon>
              <d-image
                v-else-if="item.avatar.image"
                v-bind="item.avatar.image"
                color="transparent"
                hide-placeholder
                contain
              />
            </slot>
          </v-list-item-avatar>

          <v-list-item-icon v-else-if="item.icon">
            <slot name="icon" v-bind="item">
              <v-icon v-bind="item.icon.props">
                {{ item.icon.name }}
              </v-icon>
            </slot>
          </v-list-item-icon>

          <v-list-item-content>
            <slot name="content" v-bind="item">
              <v-list-item-title v-html="item.title"/>
              <v-list-item-subtitle
                v-if="item.subtitle"
                v-html="item.subtitle"
              />
            </slot>
          </v-list-item-content>

          <v-list-item-action v-if="item.action">
            <slot name="action" v-bind="item">
              <v-icon
                v-if="item.action.icon"
                v-bind="item.action.icon.props"
                v-on="item.action.on"
              >
                {{ item.action.icon.name }}
              </v-icon>
              <!-- TODO: Bullet component -->
            </slot>
          </v-list-item-action>

        </v-list-item>
      </template>
    </component>
  </v-list>
</template>

<script>
import { VList, VListItemGroup } from 'vuetify/lib';
import DImage from '@/components/Image';

const computeIcon = icon => {
  if ( ! icon ) return;
  if ( typeof icon === 'string' ) {
    icon = { name: icon };
  } else {
    const { name, ...props } = icon;
    icon = { name, props };
  }
  return icon;
}

const computeImage = image => {
  if ( ! image ) return;
  if ( typeof image === 'string' ) image = { src: image };
  const { src, ...props } = image;
  return { ...props, value: { url: src }, itemImage: 'url' };
};

const computeAvatar = avatar => {
  if ( ! avatar ) return;
  if ( typeof avatar === 'boolean' ) {
    return { props: { color: '#f4f4f4' }};
  } else if ( typeof avatar === 'string' ) {
    if (/\.[^.]+$/.test( avatar )) {
      return { props: {}, image: computeImage( avatar )};
    } else {
      return { props: {}, icon: computeIcon( avatar )};
    }
  } else {
    let { icon, image, ...props } = avatar;
    return {
      props,
      icon: computeIcon( icon ),
      image: computeImage( image )
    };
  }
};

const computeBullet = bullet => {
  if ( ! bullet ) return;
  if ( typeof bullet === 'boolean' ) {
    return { props: { color: 'primary' }};
  } else if ( typeof bullet === 'number' ) {
    return { number: bullet, props: { color: 'primary' }};
  } else {
    let { number, ...props } = bullet;
    return { number, props };
  }
};

const computeAction = action => {
  if ( ! action ) return;
  if ( typeof action === 'boolean' ) {
    return { bullet: computeBullet( true ) };
  } else if ( typeof action === 'string' ) {
    return { icon: computeIcon( action ) };
  } else {
    let { icon, bullet, ...on } = action;
    return {
      icon: computeIcon( icon ),
      bullet: computeBullet( bullet ),
      on
    };
  }
};

export default {
  components: { VListItemGroup, DImage },
  props: {
    ...VList.options.props,
    ...VListItemGroup.options.props,
    padless: Boolean,
    divider: Boolean,
    backgroundColor: String,
    inactive: Boolean,
    selectable: {
      type: Boolean,
      default: true
    },
    iconSize: {
      type: [ Number, String ],
      default: 30
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    classes() {
      return {
        list: true,
        '--padless': this.padless
      }
    },
    listProps() {
      return Object.keys( this.$props )
        .filter( key => VList.options.props[key] )
        .reduce(( obj, key ) => {
          obj[key] = this.$props[key];
          return obj;
        },{});
    },
    groupTag() {
      return this.selectable
        ? 'v-list-item-group'
        : 'div';
    },
    groupProps() {
      if ( ! this.selectable ) return {};
      return {
        ...Object.keys( this.$props )
        .filter( key => VListItemGroup.options.props[key] )
        .reduce(( obj, key ) => {
          obj[key] = this.$props[key];
          return obj;
        },{}),
        tag: undefined,
        dark: undefined,
        light: undefined
      };
    },
    internalItems() {
      if ( ! this.divider ) return this.items;
      return this.items
        .map( item => [ item, { divider: true }])
        .flat().slice( 0, -1 );
    },
    computedItems() {
      return this.internalItems.map(( item, index ) => {

        if ( item.divider || item.header ) {
          return { ...item, item, index };
        }

        const title = item.title || item.text;
        return {
          title,
          subtitle: item.subtitle,
          icon: computeIcon( item.icon, title ),
          avatar: computeAvatar( item.avatar, title ),
          action: computeAction( item.action ),
          item,
          index
        };
      });
    }
  }
}
</script>

<style>
.list.--padless {
  padding: 0;
}
.list .v-subheader--inset {
  margin-left: 50px;
}
.list .v-list-item__icon:first-child {
  margin-right: 12px;
}
.list .v-list-item__avatar {
  transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-navigation-drawer--mini-variant .list .v-list-item > *:first-child {
  margin-left: 3px;
  margin-right: 3px;
}
</style>
