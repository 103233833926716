import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import icons from '@/components/icons';
import es from 'vuetify/lib/locale/es';

Vue.use( Vuetify );

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#8C9B6E',
        secondary: '#AD6975',
        success: '#8C9B6E',
        error: '#AD6975',
        accent: '#AD6975',
        background: '#f5f5f5'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdi',
    values: icons
  }
});
