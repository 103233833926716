<template>
  <v-img
    v-bind="colorAttrs"
    v-on="listeners"
    :src="image"
    :title="title || computedPlaceholder"
  >

    <slot v-if="loading" name="loading">

      <div
        v-if="rounded"
        class="d-flex align-center justify-center fill-height"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>

      <v-progress-linear
        v-else
        color="primary"
        indeterminate
        absolute
        top
      />

    </slot>

    <slot v-else-if="isBroken" name="error">
      <div class="d-flex align-center justify-center layer absolute">

        <v-icon color="error">
          {{ brokenIcon }}
        </v-icon>

      </div>
    </slot>

    <slot v-else-if="!image && !preview" name="placeholder">
      <div v-if="computedPlaceholder" class="d-flex align-center justify-center layer absolute">

        <span class="v-image--placeholder text-center">
          {{ computedPlaceholder }}
        </span>

      </div>
    </slot>

    <slot/>

  </v-img>
</template>

<script>
import colorable from 'vuetify/lib/mixins/colorable';
import { IMAGE_BASE_URL } from '@/utils/constants';

const imageGetter = ( value, key ) => {
  if ( ! value ) return;
  if ( typeof value === 'string' ) return value;
  if ( typeof key === 'function' ) return imageGetter( key( value ));
  if ( typeof key === 'string' ) return imageGetter( value[key] );
  return;
};

export default {
  mixins: [ colorable ],
  props: {
    value: null,
    preview: String,
    error: Boolean,
    loading: Boolean,
    placeholder: String,
    hidePlaceholder: Boolean,
    outlined: Boolean,
    rounded: Boolean,
    title: String,
    baseUrl: {
      type: String,
      default: IMAGE_BASE_URL
    },
    itemImage: {
      type: [ String, Function ],
      default: 'url'
    },
    brokenIcon: {
      type: String,
      default: 'mdi-image-off'
    },
    color: {
      type: String,
      default: 'grey lighten-3'
    }
  },
  data: () => ({
    internalValue: undefined,
    image: undefined,
    isLoading: false,
    isBroken: false
  }),
  computed: {
    classes() {
      return {
        'rounded-circle': this.rounded,
        'v-image--error': this.error || this.isBroken,
        'v-image--loading': this.loading,
        'v-image--outlined': this.outlined
      }
    },
    listeners() {
      return {
        ...this.$listeners,
        error: this.onError
      };
    },
    colorAttrs() {
      return {
        ...this.setBackgroundColor( this.color, { class: this.classes }),
        ...this.$attrs
      };
    },
    computedPlaceholder() {
      if ( this.hidePlaceholder ) return null;
      return this.placeholder || this.$t('inputs.picture.placeholder');
    },
  },
  watch: {
    value: 'set',
    itemImage: 'set',
    internalValue( value ) {
      this.$emit( 'input', value );
    }
  },
  methods: {
    set() {
      if ( ! this.isLoading ) this.isBroken = false;
      this.image = this.computeImage();
      this.internalValue = ( this.image ? this.value : 0 ) || undefined;
    },
    computeImage() {
      if ( this.preview ) return this.preview || undefined;
      else {
        let image = imageGetter( this.value, this.itemImage );
        if ( image && this.baseUrl ) {
          image = [
            this.baseUrl.replace(/\/$/,''),
            image.slice( image.lastIndexOf('/') + 1 )
          ].join('/');
        }
        return image || undefined;
      }
    },
    onLoadStart() {
      this.isLoading = true;
      this.$emit( 'loadstart' );
    },
    onLoad() {
      this.isLoading = false;
      this.$emit( 'load' );
    },
    onError() {
      this.isLoading = false;
      if ( this.image ) this.isBroken = true;
      this.$emit( 'error', this.isBroken );
    }
  },
  beforeMount() {
    this.set();
  }
}
</script>

<style>
.v-image.v-image--outlined {
  box-shadow: 0 0 0 1px rgba(0,0,0,.12) inset;
}
.v-image.v-image--error {
  box-shadow: 0 0 0 2px var(--v-error-base) inset;
}
</style>
