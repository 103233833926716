<template>
  <v-btn
    v-bind="$props"
    v-on="$listeners"
    :icon="!fab && ( !!internalIcon || icon )"
  >

    <v-icon
      v-if="iconLeft"
      v-text="iconLeft"
      left
    />

    <v-icon
      v-if="internalIcon"
      v-text="internalIcon"
      :color="iconColor"
    />

    <slot
      v-else
    />

    <v-icon
      v-if="iconRight"
      v-text="iconRight"
      right
    />

  </v-btn>
</template>

<script>
import VBtn from 'vuetify/lib/components/VBtn';
import VIcon from 'vuetify/lib/components/VIcon';
import { componentProps } from '@/utils';
import config from './defaults';

const defaultProps = { ...VBtn.options.props };

defaultProps.iconLeft =
defaultProps.iconRight =
defaultProps.iconColor = String;
defaultProps.icon = {
  type: [ Boolean, String ],
  default: false
};

export default {
  name: 'Btn',
  components: { VBtn, VIcon },
  props: componentProps( defaultProps, config ),
  computed: {
    internalIcon() {
      return typeof this.icon === 'string' && this.icon;
    }
  }
};
</script>

<style>
.theme--dark.v-btn > .v-btn__content .v-icon,
.theme--light.v-btn > .v-btn__content .v-icon,
.theme--dark.v-btn.v-btn--disabled,
.theme--light.v-btn.v-btn--disabled {
  color: inherit !important;
}
.theme--dark.v-btn.v-btn--disabled,
.theme--light.v-btn.v-btn--disabled {
  opacity: .4;
}
</style>
